import '../styles/index.scss';
import '../scripts/acf-map.js';


$('.nav__trigger').on('click', function(){
  $('.nav').toggleClass('active');
});

$('.target_contact_modal').on('click', function(){
  $('.modal__holder.contact').toggleClass('open');

  if ($(this).find('a').data('comment')) {
    $('.modal__holder').find('textarea').val($(this).find('a').data('comment'));
  }
});

$('.collapse__bar').on('click', function(){
  $(this).closest('.products__pros').toggleClass('open');
});

$('.close__button').on('click', function(){
  $('.modal__holder').removeClass('open');
});

$('.banner__slider').slick({
    prevArrow: '<button type="button" class="slick-prev"><i class="far fa-angle-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="far fa-angle-right"></i></button>'
});

$('.history__slider').slick({
  prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
  nextArrow: '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
  slidesToShow: 1,
  infinite: false,
  fade: true,
  speed: 350,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
  ]
});

$('.nav__trigger').hover(
  function(){ $('.nav').addClass('active') },function(){}
);

$('.nav__menu').hover(
  function(){ 
    $('.nav').addClass('active') 
  },
  function(){ 
    setTimeout(() => {
      $('.nav').removeClass('active') 
    }, 700);
  }
);

$('.nav__menu .parent').hover(
  function(){ 
    $(this).addClass('open') 
  },
  function(){
    setTimeout(() => {
      $(this).removeClass('open') 
    }, 1000);
  }
);

$("#scrollto").click(function() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(".gallery__holder").offset().top - 100
  }, 2000);
});

$("#scrolltohistory").click(function() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $(".history").offset().top - 100
  }, 2000);
});

$('.full__slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: '<button type="button" class="slick-prev"><i class="fal fa-angle-left"></i></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fal fa-angle-right"></i></button>',
  	centerMode: true,
    variableWidth: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
    		variableWidth: false,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        }
    ]
});

$(document).ready(function() {
  locationHashChanged();
});

function locationHashChanged() {
  var target = window.location.hash.substring(1);
  if (target != '') {
      console.log(target);
      $([document.documentElement, document.body]).animate({
          scrollTop: $("." + target).offset().top - 100
      }, 1500);
  }
}

window.onhashchange = locationHashChanged;

// Create templates for buttons
$.fancybox.defaults.btnTpl.send = '<button data-fancybox-send class="fancybox-button fancybox-button--send" title="Mail naar Pacton">Stuur foto naar Pacton</button>';

// Make buttons clickable using event delegation

$('body').on('click', '[data-fancybox-send]', function(e) {
    e.preventDefault();
    $('.modal__holder.img').toggleClass('open');
    $('.modal__holder').find('textarea').val('Ik heb een vraag over de volgende afbeelding: ' + window.location.href);
});


// Custom options
$( '[data-fancybox]:not(.video):not(.spreadsheet)' ).fancybox({
  buttons : [
    'send',
    'close'
  ]
});

$(document).ready(function(){
    function isMobileOrTablet() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    // Check if the screen size is smaller than a certain threshold and if the element has data-fancybox attribute
    if (isMobileOrTablet()) { // Assuming tablet and mobile width is below 768px
        $('a.spreadsheet').each(function() {
            var link = $(this);

            // Check if the element has data-fancybox attribute
            if (link.is('[data-fancybox]')) {
                // Get the URL from data-src attribute
                var url = link.attr('data-src');

                // Remove data-src attribute and add the URL to href attribute
                link.removeAttr('data-src').attr('href', url);

                // Remove data-fancybox attribute and add target="_blank" attribute
                link.removeAttr('data-fancybox').attr('target', '_blank');
            }
        });
    }
});